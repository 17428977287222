h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure {
  padding-bottom: 10px;
}

h1 {
  font-size: 2em !important;
  font-weight: bolder !important;
}

h2 {
  font-size: 1.5em !important;
  font-weight: bolder !important;
}

h3 {
  font-size: 1.17em !important;
  /* font-weight: bolder !important; */
}

h4 {
  font-size: 1em !important;
  /* font-weight: bolder !important; */
}

h5 {
  font-size: 0.83em !important;
  /* font-weight: bolder !important; */
}

h6 {
  font-size: 0.67em !important;
  /* font-weight: bolder !important; */
}

.textAlignRight {
  text-align: right !important;
}

.textAlignCenter {
  text-align: center !important;
}

.textAlignLeft {
  text-align: left !important;
}

ul {
  list-style-type: initial;
  padding-left: 40px;
}
